<template>
    <ion-page>
        <ion-header :translucent="true">
            <ion-toolbar>
                <ion-title color="white">Immagini rapportino</ion-title>
                <ion-buttons slot="start">
                    <ion-button @click="closeModal()">
                        <ion-icon slot="start" :icon="arrowBackOutline" color="white"></ion-icon>
                    </ion-button>
                </ion-buttons>
                <!-- <ion-buttons v-if="!daRapportino" slot="start">
                    <ion-button @click="closeModal()">
                        <ion-icon slot="start" :icon="arrowBackOutline" color="white"></ion-icon>
                    </ion-button>
                </ion-buttons>
                <ion-buttons v-else slot="start">
                    <ion-button @click="goToProgramma()">
                        <ion-icon slot="start" :icon="arrowBackOutline" color="white"></ion-icon>
                    </ion-button>
                </ion-buttons> -->
            </ion-toolbar>
        </ion-header>

        <ion-content :fullscreen="true">
            <div class="page_content">
                <!-- Img modal, triggered with click on ticket attachment -->
                <Transition name="fade-transition">
                    <div v-show="showImgModal" class="attachment_modal">
                        <div class="customers_container">
                            <img :src="selectedImage" alt="Foto rapportino" />
                        </div>
                        <div @click="closeAttachmentModal()" class="close_modal">Chiudi</div>
                    </div>
                </Transition>

                <div v-if="images.length > 0">
                    <div class="rapportino">
                        <ion-grid>
                            <ion-row>
                                <ion-col size="6" v-for="(image, index) in images" :key="index" @click="openImgDetail(image)">
                                    <ion-thumbnail>
                                        <img :src="setImageUrl(image)" />
                                    </ion-thumbnail>
                                </ion-col>
                            </ion-row>
                        </ion-grid>
                    </div>
                </div>
                <div v-else class="no_photos">Nessuna foto caricata</div>
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import {
    IonPage,
    IonHeader,
    IonToolbar,
    IonButtons,
    //IonBackButton,
    IonTitle,
    IonContent,
    IonIcon,
    IonButton,
    IonGrid,
    IonRow,
    IonCol,
    IonThumbnail,
    modalController,
} from "@ionic/vue";
import { arrowBackOutline } from "ionicons/icons";

import { defineComponent, ref } from "vue";

import { openToast } from "@/services/toast";

import apiRapportini from "@/services/rapportini";

export default defineComponent({
    name: "ImmaginiRapportino",
    props: {
        rapportino_id: {
            type: String,
            required: true,
        },
    },
    components: {
        IonPage,
        IonHeader,
        IonToolbar,
        IonButtons,
        //IonBackButton,
        IonTitle,
        IonContent,
        IonIcon,
        IonButton,
        IonGrid,
        IonRow,
        IonCol,
        IonThumbnail,
    },
    setup(props, context) {
        const rapportino_id = ref(props.rapportino_id);

        /**
         * ! Handle close modal by clicking on Chiudi button
         */
        const closeModal = () => {
            modalController.dismiss();
        };

        /**
         *
         * ! Open fake modal to view selected ticket attachment
         *
         */
        const selectedImage = ref(null);
        const showImgModal = ref(false);
        function openImgDetail(image) {
            showImgModal.value = true;
            selectedImage.value = `${process.env.VUE_APP_BASE_URL}/uploads/${image.rapportini_immagini_file}`;
        }

        function closeAttachmentModal() {
            showImgModal.value = false;
        }

        function setImageUrl(img) {
            if (img) {
                return `${process.env.VUE_APP_BASE_URL}/uploads/${img.rapportini_immagini_file}`;
            }
        }

        /**
         * 
         * ! Get rapportino images
         * 
         */
        const loading = ref(false);
        const images = ref([]);
        async function loadImages() {
            loading.value = true;

            try {
                const response = await apiRapportini.getImmagini(rapportino_id.value);

                if(response.status === 200 && response.data.status === 0) {
                    images.value = response.data.data;
                } else {
                    openToast("Si è verificato un errore nel caricamento delle immagini", "toast_danger");
                }
            } catch (error) {
                openToast("Si è verificato un errore nel caricamento delle immagini", "toast_danger");
            } finally {
                loading.value = false;
            }
        }
        loadImages();

        return {
            closeModal,
            arrowBackOutline,
            images,
            showImgModal,
            selectedImage,
            openImgDetail,
            closeAttachmentModal,
            setImageUrl,
            loading,
        };
    },
});
</script>

<style scoped>
ion-toolbar {
    --background: #086fa3;
    --color: #ffffff;
}
ion-button {
    --color: #ffffff;
}

.loading_spinner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* New rules */
.page_content {
    width: 100%;
    position: relative; /* to be used for new intervento btn */
    padding: 16px;
    min-height: 100%;
    background: #f8fafc;
}

.rapportino {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding: 16px;
    border-radius: 8px;
    /* box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1); */
    background: #ffffff;
    box-shadow: 0px 2px 5px 0px #00000040;
}

.field .title,
.field_note .title {
    font-size: 14px;
    font-weight: 500;
    color: #1D1D1B;
}
.field .ticket_title {
    text-transform: uppercase;
}
.field_note .title {
    margin-bottom: 8px;
}
.field .value,
.field_note .value {
    font-size: 14px;
}


/** Modal selected attachment */
.fade-transition-enter-active,
.fade-transition-leave-active {
    transition: opacity 0.25s ease;
}

.fade-transition-enter-from,
.fade-transition-leave-to {
    opacity: 0;
}
.attachment_modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.75);
    color: #ffffff;
    padding: 24px;
    align-items: center;
}
.close_modal {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    color: #ffffff;
    background-color: #086fa3;
    font-size: 16px;
    font-weight: 500;
    border-radius: 20px;
    margin-top: 16px;
}

.title.rapportino_container {
    display: flex;
    justify-content: center;
    margin-top: 8px;
    font-size: 16px;
}
a.link_rapportino {
    color: #086fa3;
    text-decoration: none;
}

.no_photos {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px;
    font-weight: 500;
    font-size: 18px;
    color: #086fa3;
    background-color: rgb(8 111 163 / 11%);
    border-radius: 8px;
}

ion-thumbnail {
    display: block;
    width: 100%; /* Ensure thumbnails take full width of the column */
    height: auto; /* Maintain aspect ratio */
}
</style>
